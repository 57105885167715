<template>
  <nav class="navbar">
    <section class="navbar-core">
      <section class="columns is-multiline">
        <section class="column is-4-tablet is-12-mobile">
          <p class="title is-13px is-dark">
            GODZINY OTWARCIA: <span class="is-light">PON - PT: 7 - 16</span>
          </p>
        </section>
        <section class="column is-4-tablet is-12-mobile">
          <p class="title is-13px is-dark">
            ZADZWOŃ DO NAS: <span class="is-light">61 878 08 24</span>
          </p>
        </section>
        <section class="column is-4-tablet is-12-mobile">
          <p class="title is-13px is-dark">
            NAPISZ DO NAS: <span class="is-light">KONTAKT@TECHNIKER.PL</span>
          </p>
        </section>
      </section>
    </section>
  </nav>
</template>

<script>
export default {
  name: 'Navbar',
};
</script>

<style lang="scss" scoped>
.navbar {
  display: grid;
  margin: 0 auto;
  background: $primary;
  align-items: center;
  padding: 1rem 2rem;
}

.navbar-core {
  margin: 0 auto;
}

@media only screen and (max-width: 768px) {
  .navbar-core {
    width: 100%;
  }
}

@media only screen and (min-width: 769px) {
  .navbar-core {
    width: 80%;
  }
}

.no-padding {
  padding: 0;
}

.is-13px {
  font-size: 13px;
}

.is-dark {
  color: $grey;
}

.column {
  text-align: left;
}

.is-light {
  font-weight: initial;
}
</style>
